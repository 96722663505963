import React from 'react'
import './services.css';

import {Footer} from '../../containers';
import {Navbar, Feature} from'../../components';

const servicesData = [
  {
    title: 'Training and Development',
    text: 'Training modules, workshops, and leadership programs to upskill staff and foster a culture of excellence.',
  },
  {
    title: 'Operational Efficiency Templates',
    text: 'Customizable templates and guidelines to streamline operations, enhance compliance, and improve efficiency.',
  },
  {
    title: 'Compliance and Regulatory Guidance',
    text: 'Expert advice and checklists to ensure compliance with South Africa’s regulatory landscape and industry standards.',
  },
  {
    title: 'Sales and Marketing Strategies',
    text: 'Tailored sales strategies and marketing templates to expand client base, increase market share, and boost brand visibility.',
  },
  {
    title: 'Technology Integration',
    text: 'Recommendations for the latest security technologies, implementation support, and training on new technology usage and best practices.',
  },
  {
    title: 'Customer Service Improvement',
    text: 'Customer service training programs, best practices for client communication and satisfaction, and complaint resolution strategies.',
  },
  {
    title: 'Crisis Management Planning',
    text: 'Development of crisis management plans, crisis response training and simulations, and post-crisis evaluation and recovery strategies.',
  },
  {
    title: 'Strategic Planning and Business Development',
    text: 'Long-term strategic planning, market analysis and business development strategies, and performance benchmarking and improvement plans.',
  },
]

const Services = () => {
  return (
    <div>
      <Navbar />
      {/* our serices header and content */}
      <div className="psic_services-header f-col gp-s f-jc f-ac">
          <p className='page-title'>Our Services</p>
          <p className='content'>At Private Security Industry Consultants, we offer a wide range of consulting services. With over 30 years of combined experience, our expert team provides exceptional, tailored solutions for our clients' unique needs.</p>
      </div>
      {/* PSIC services in blocks */}
      <div className="psic_services f-je section_padding">
        <div className="psic_services-container">
          {servicesData.map((item, index) => (
            <Feature title={item.title} text={item.text} key={item.title  + index} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Services