import React from 'react'
import './footer.css';

import {FaWhatsapp , FaFacebookF, FaInstagram} from 'react-icons/fa6';
import logo from '../../asserts/logo.png'

const Footer = () => {
  return (
    <div className='psic_footer section_padding'>
      <div className="psic_footer_top-section">
        <div className="psic_footer_logo-image">
          <img src={logo} alt="private security industry consultants logo" />
        </div>
        <div className="psic_footer_cta-content">
          <h1>Private Security <br/><span>Industry Consultant</span></h1>
          <p>our team of seasoned experts is committed to delivering unparalleled consulting services tailored to the unique needs of our clients.</p>
        </div>
      </div>
      <div className="psic_footer_bottom-section">
        <div className="psic_footer_copyright">
          <p>&copy; 2024 private security industry consultants. All rights reserved.</p>
        </div>
        <div className="psic_footer_social-icons">
          <a className='icons' href="https://www.facebook.com/psic.co.za" target='_blank'><FaFacebookF /></a>
          <a className='icons' href="https://www.instagram.com/psic.southafrica" target="_blank"><FaInstagram /></a>
          <a className='icons' href="#"><FaWhatsapp /></a>
        </div>
      </div>
    </div>
  )
}

export default Footer