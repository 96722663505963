import React from 'react'
import './ourExpectise.css';

import securityImage002 from '../../asserts/securityImage002.jpg'; 

const OurExpectise = () => {
  return (
    <div className='psic_OurExpectise section_padding'>
      <div className="psic_OurExpectise-image">
        <img src={securityImage002} alt="" />
      </div>
      <div className="OurExpectise-content">
        <h1>Our Expertise</h1>
        <p>Our team comprises industry veterans with extensive experience in various facets of the private security industry. With over three decades of combined expertise, we bring a wealth of knowledge and practical insights to our clients, enabling them to overcome challenges and seize opportunities in a competitive market.</p>
      </div>
    </div>
  )
}

export default OurExpectise
