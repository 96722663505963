import React from 'react';
import {Link} from 'react-router-dom'
import './thankyou.css'
import { MdOutlineKeyboardBackspace } from "react-icons/md";

const ThankYou = () => {
  return (
    <div className='psic_ThankYou section_padding'>
      <h1>Thank You!</h1>
      <p>Your email has been sent successfully. We will get back to you shortly.</p>
      <div className="home-btn btn-2">
        <MdOutlineKeyboardBackspace />
        <Link to='/'>return to home</Link>
      </div>
    </div>
  );
};

export default ThankYou;
