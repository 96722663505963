import React from 'react';
import { Link } from 'react-router-dom';
import './notfound.css';
import { MdOutlineKeyboardBackspace } from "react-icons/md";

const NotFound = () => {
    return (
        <div className='psic_NotFound section_padding'>
            <h1>404 - Page Not Found</h1>
            <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
            <div className="home-btn btn-2">
                <MdOutlineKeyboardBackspace />
                <Link to='/'>go to home</Link>
            </div>
        </div>
    );
};

export default NotFound;
