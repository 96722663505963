import React from 'react';
import { Outlet } from 'react-router-dom';
import { Mission, Footer } from './containers';
import { OurExpectise } from './components';

const Nav = () => {
  return (
    <div>
      <Outlet />
      <Mission />
      <OurExpectise />
      <Footer />
    </div>
  );
}

export default Nav;
