import React from 'react'
import './mission.css';


const Mission = () => {
  return (
    <div className='psic_mission section_padding'>
      <h1>Mission</h1>
      <p>Our mission is to empower private security companies with the knowledge, tools, and strategies necessary to achieve operational excellence and sustainable growth. We aim to be the trusted partner for security firms seeking to optimize their performance and achieve their business objectives.</p>
    </div>
  )
}

export default Mission