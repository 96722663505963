import React from 'react';
import './form.css';

const Form = () => {
  return (
    <div className='psic_form'>
      <div className="psic_form-input">
        <form action="https://formsubmit.co/76cfb2d04b850cc5925729a6e278514d" method="POST">
          <fieldset>
            <div className="field-Hcontact">
              <div className="form-frstName field">
                <input
                  id="first-name"
                  name="firstName"
                  type="text"
                  placeholder=""
                  required
                />
                <label htmlFor="first-name">First Name</label>
              </div>
              <div className="form-lastName field">
                <input
                  id="last-name"
                  name="lastName"
                  type="text"
                  placeholder=""
                  required
                />
                <label htmlFor="last-name">Last Name</label>
              </div>
            </div>
            <div className="field-Vcontact">
              <div className="phone-number field">
                <input
                  id="phone-number"
                  name="phoneNumber"
                  type="tel"
                  placeholder=""
                  required
                />
                <label htmlFor="phone-number">Phone Number</label>
              </div>
              <div className="email field">
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder=""
                  required
                />
                <label htmlFor="email">Email</label>
              </div>
              <div className="field">
                <textarea
                  id="message"
                  name="message"  
                  rows="5"
                  cols="30"
                  placeholder="Send us a message"
                ></textarea>
                <label htmlFor="message">Message</label>
              </div>
              <input type="hidden" name="_subject" value="Website Clients"></input>
              <input type="hidden" name="_captcha" value="false"></input>
              <input type="hidden" name="_next" value="https://www.psic.co.za/thankyou"></input>
              <input type="hidden" name="_template" value="box"></input>
            </div>
            <div className="psic-button btn">
              <input type="submit" value="send message" />
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default Form;
