import React from 'react'
import './document.css';

import {Footer} from '..';
import {Navbar} from'../../components';

import securityCamera001 from '../../asserts/securityCamera002.jpg';
import securityImage003 from '../../asserts/securityImage003.jpg';

import { RxLapTimer } from "react-icons/rx";
import { FaStream } from "react-icons/fa";
import { FaFileLines } from "react-icons/fa6";


const Document = () => {
  return (
    <div>
      <Navbar />
      <div className="psic_features section_padding">
        <div className="psic_feature-content">
          <div className="psic_feature_content-top">
            <div className="left-content">
              <div className="content-section">
                <h1>Introducing the Private Security Industry Documents Package</h1>
                <h4>Dear Private Security Company,</h4>
                <p>We are delighted to introduce you to the Private Security Industry Documents toolkit, an essential toolkit designed to elevate your private security company’s operations, efficiency, and professionalism. At Private Security Industry Consultants (PSIC), we understand the unique challenges faced by security firms in South Africa. That’s why we’ve developed this comprehensive collection of over 200 expertly crafted templates, aimed at supporting both startups and established companies in achieving their business goals.</p>
              </div>
              <div className="content-section">
                <h1>About the Package</h1>
                <p>Our document package has been meticulously compiled by a team of seasoned Private Security Specialists, each bringing over 10 years of industry experience. This ensures that every template is tailored to meet the specific needs of private security companies, helping you streamline processes, maintain compliance, and present your business professionally.</p>
              </div>
              <div className="content-section wcdp">
                <h1>Why Choose Our Document Package?</h1>
                <div className="wcdp-p">
                  <div className="wcdp-icon">
                   <RxLapTimer />
                  </div>
                  <div className="wcdp-content">
                    <h4>Save Time and Effort</h4>
                    <p>Our ready-to-use templates significantly reduce the time spent on creating and organizing documents from scratch, allowing you to focus on core business activities.</p>
                  </div>
                </div>
                <div className="wcdp-p">
                <div className="wcdp-icon">
                   <FaStream />
                  </div>
                  <div className="wcdp-content">
                    <h4>Enhance Efficiency</h4>
                    <p>Structured, professional documents ensure consistency and streamline your company’s processes, leading to improved operational efficiency.</p>
                  </div>
                </div>
                <div className="wcdp-p">
                <div className="wcdp-icon">
                   <FaFileLines />
                  </div>
                  <div className="wcdp-content">
                    <h4>Increase Tender Success</h4>
                    <p>Comprehensive and well-organized templates improve your tender submissions, increasing your chances of winning new contracts and expanding your business.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-content">
              <img src={securityCamera001} alt="" />
            </div>
          </div>
          <div className="psic-feature-content-bottom">
            <div className='feature-content display-flex-col'>
              <h1>What’s Included?</h1>
              <p>The Private Security Industry Documents package covers every critical aspect of running a private security company. The documents are categorized into the following sections:</p>
              <div className="packageInclude display-flex-row">
                <div className="content display-flex-col">
                  <p>Administrative Documents</p>
                  <p>Human Resource Documents</p>
                  <p>Health and Safety Documents</p>
                  <p>Control Room Documents</p>
                </div>
                <div className="content display-flex-col">
                  <p>Operations Documents</p>
                  <p>Sales and Marketing Documents</p>
                  <p>Financial Documents</p>
                  <p>Firearm Control Documents</p>
                </div>
              </div>
            </div>
            <div className='feature-image'>
              <img src={securityImage003} alt="" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Document