import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Contact, Services, Header, Document } from './containers';
import { NotFound, ThankYou } from './components';
import Nav from './nav.js';
import './App.css';

const App = () => {
  return (
    <div className='App'>
      <Router>
        <div className='gradient-bg'>
          <Routes>
            <Route path="/" element={<Nav />}>
              <Route index element={<Header />} />
            </Route>
            <Route path="/services" element={<Services />} />
            <Route path="/document" element={<Document />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/thankyou" element={<ThankYou />} /> {/* Add Thank You route */}
            <Route path="*" element={<NotFound />} /> {/* Catch all route for 404 Not Found */}
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
