import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import './navbar.css';
import PSIC from '../../asserts/PSIC.png';
import {RiMenu3Line, RiCloseLine } from 'react-icons/ri';

const Menu = () => (
  <>
    <p><Link to='/'>Home</Link></p>
    <p><Link to='/services'>Services</Link></p>
    <p><Link to='/document'>Document Packages</Link></p>
    <p><Link to='/contact'>Contact Us</Link></p>
  </>
)

const Navbar = () => {

  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className='psic_navbar glass__background'>
      <div className="psic_navbar-links">
        <div className="psic_navbar-links_container">
          <p><Link to='/'>Home</Link></p>
          <p><Link to='/services'>Services</Link></p>
          <p><Link to='/document'>Document Packages</Link></p>
          <p><Link to='/contact'>Contact Us</Link></p>
        </div>
      </div>

      <div className="psic_navbar-logo">
        <img src={PSIC} alt="" />
      </div>

      <div className="psic_navbar-toggle">
        {toggleMenu
          ? <RiCloseLine size={24} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line size={24} onClick={() => setToggleMenu(true)} />
        }
        {toggleMenu && (
          <div className='psic_navbar-menu_container scale-up-center'>
            <div className="psic_navbar-menu_container-links">
              <Menu />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar