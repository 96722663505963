import React from 'react'
import './feature.css';

const Feature = ({title, text}) => {
  return (
    <div>
      <div className="psic_feature">
        <div className="psic_feature-title">
          <div />
          <h1>{title}</h1>
        </div>
        <div className="psic_feature-text">
          <p>{text}</p>
        </div>
      </div>
    </div>
  )
}

export default Feature