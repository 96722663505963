import React from 'react'
import { Link } from 'react-router-dom';
import './header.css';
import {Navbar} from '../../components';

import securityImage001 from '../../asserts/securityImage001.jpg';

const Header = () => {
  return (
    <div className='psic_header'>
      <Navbar />
      <div className="psic_header-content section_padding">
        <div className="psic-hero-content">
          <div className="psic_header-logo">
            <h1>private security <br />
            <span>industry consultants</span>
            </h1>
          </div>
          <p>Private Security Industry Consultants is a premier consulting firm based in South Africa, dedicated to enhancing the operational and sales efficiency of private security companies.</p>
          <div className="psic_hero_content-cta">
            <button className='btn' type='button'><Link to='/document'>Learn more</Link></button>
          </div>
        </div>
        <div className="psic_header-image">
          <img src={securityImage001} alt="a security guard" /> 
        </div>
      </div>
    </div>
  )
}

export default Header