import React from 'react';
import './contact.css';

import {Form} from '../../containers';
import {Navbar} from'../../components';

import { MdBusiness } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import {FaWhatsapp , FaFacebookF, FaInstagram} from 'react-icons/fa6';
import { MdEmail } from "react-icons/md";

const Contact = () => {
  return (
    <div className='psic_contact'>
      <Navbar />
      <div className="psic_contact-form section_padding">
        <div className="contained">
          <div className="psic_form-content">
            <h1>get in touch</h1>
            <p>Contact us today to learn more about our services and how we can help you achieve operational excellence and sustainable growth.</p>
            <div className="contact-reach">
              <div className="address">
                <div className="icon-reach">
                  <MdBusiness size={24} />
                </div>
                <div className="loc">
                  <p>39 Rivonia Road</p>
                  <p>Sandhurst</p>
                  <p>Sandton</p>
                  <p>Gauteng 2196</p>
                </div>
              </div>
              <div className="phone">
                <div className="icon-reach">
                  <FaPhone size={24} />
                </div>
                <p>+27 64 291 4805</p>
              </div>
              <div className="email">
                <div className="icon-reach">
                  <MdEmail size={24} />
                </div>
                <p>info@psic.co.za</p>
              </div>
            </div>
          </div>
          <div className="psic-form">
            <Form />
          </div>
        </div>
        <div className="psic_contact_social-icons">
          <a className='icons' href="https://www.facebook.com/psic.co.za" target='_blank'><FaFacebookF size={24} /></a>
          <a className='icons' href="https://www.instagram.com/psic.southafrica" target="_blank"><FaInstagram size={24} /></a>
          <a className='icons' href="#"><FaWhatsapp size={24} /></a>
        </div>
        <div class="overlay"></div>
      </div>
    </div>
  )
}

export default Contact